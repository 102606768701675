<template>
	<div class="home">
		<div class="content">
			<!-- 顶部 -->
			<!-- <Header titleName="首页" /> -->
			<!-- <div style="height: 1.1333rem;background-color: #fff;"></div> -->
			<!-- <div class="banner">
				<img class="empty_img" v-if="!data.bannerObj.filePath" src="@/assets/images/empty_img.png" alt="">
				<img class="banner_img" v-else :src="data.bannerObj.filePath" alt="">
			</div> -->
			<!-- 轮播 -->
			<van-swipe :autoplay="3000" :loop="true" @change="onChange2">
				<van-swipe-item v-for="(item, index) in data.swiper" :key="index"
					@click="toBannerDetails(item.linkUrl)">
					<img v-if="item.filePath" :src="item.filePath" alt="">
					<img class="empty_img" v-else src="@/assets/images/empty_img.png" alt="">
				</van-swipe-item>
				<template #indicator>
					<div class="custom-indicator">
						<div class="box" v-for="(item, index) in data.swiper" :key="index"
							:class="index == data.current ? 'active' : ''">
						</div>
					</div>
				</template>
			</van-swipe>
			<div class="list_view">
				<div class="list_view_title">
					<img class="btn_title_bg" src="@/assets/images/myHome/sta_dynamic_top.png" alt="">
				</div>
				<div class="list_nav">
					<ul>
						<li @click="changeDataDynamic(0)" :class="data.cur_index == 0 ? 'li_select' : ''">2024</li>
						<li @click="changeDataDynamic(1)" :class="data.cur_index == 1 ? 'li_select' : ''">2023</li>
						<li @click="changeDataDynamic(2)" :class="data.cur_index == 2 ? 'li_select' : ''">2022</li>
						<li @click="changeDataDynamic(3)" :class="data.cur_index == 3 ? 'li_select' : ''">2021</li>
						<li @click="changeDataDynamic(4)" :class="data.cur_index == 4 ? 'li_select' : ''">2020</li>
					</ul>
				</div>
				<div>
					<div class="data_lists">
						<div class="data_title">
							<b>{{ data.cur_data_dynamic[0].date }}</b>{{ data.cur_data_dynamic[0].title }}
						</div>
						<div class="data_data">
							<span>{{ data.cur_data_dynamic[0].data }}</span><em>{{ data.cur_data_dynamic[0].unit }}</em>
						</div>
					</div>
					<div class="data_lists">
						<div class="data_title">
							<b>{{ data.cur_data_dynamic[1].date }}</b>{{ data.cur_data_dynamic[1].title }}
						</div>
						<div class="data_data">
							<span>{{ data.cur_data_dynamic[1].data }}</span><em>{{ data.cur_data_dynamic[1].unit }}</em>
						</div>
					</div>
					<div class="data_lists">
						<div class="data_title">
							<b>{{ data.cur_data_dynamic[2].date }}</b>{{ data.cur_data_dynamic[2].title }}
						</div>
						<div class="data_data">
							<span>{{ data.cur_data_dynamic[2].data }}</span><em>{{ data.cur_data_dynamic[2].unit }}</em>
						</div>
					</div>
				</div>
			</div>
			<div class="btn_view">
				<div @click="toNewDetails('about')">
					<img src="@/assets/images/myHome/zhhjj.png" alt="">
				</div>
				<div @click="toNewDetails('545')">
					<img src="@/assets/images/myHome/cpjj.png" alt="">
				</div>
				<div @click="toNewDetails('543')">
					<img src="@/assets/images/myHome/jgsz.png" alt="">
				</div>
				<div @click="toNewDetails('544')">
					<img src="@/assets/images/myHome/lxwm.png" alt="">
				</div>
			</div>
		</div>
		<!-- 底部tab切换 -->
		<Footer />
	</div>
</template>
<script setup>
import Footer from "../../components/Footer.vue";
import {
	reactive,
	toRefs,
	onMounted
} from "vue";
import Header from "../../components/Header.vue";
import {
	useRoute,
	useRouter
} from 'vue-router';
import {
	jsToCallApp
} from '../../common/js/callApp1.0.0'
import Api from "../../utils/api";
import api from "../../utils/api";
const route = useRoute()
const router = useRouter()
let data = reactive({
	swiper: [],
	current: 0,
	bannerObj: {},
	details: {},
	data_dynamic: [],
	cur_data_dynamic: [{
		"date": "",
		"title": "",
		"data": "",
		"unit": ""
	}, {
		"date": "",
		"title": "",
		"data": "",
		"unit": ""
	}, {
		"date": "",
		"title": "",
		"data": "",
		"unit": ""
	}],
	cur_index: 0
});
onMounted(() => {
	document.title = '中国职工保障互助会'
	getBanner()
	getMsg()
	initDataDynamic()
});
const onChange2 = (index) => {
	data.current = index;
};
function initDataDynamic() {
	data.data_dynamic.push([{
		"date": "1-8月",
		"title": "互助金支出",
		"data": "8.58",
		"unit": "亿元"
	}, {
		"date": "1-8月",
		"title": "保障人次",
		"data": "1661",
		"unit": "万人次"
	}, {
		"date": "1-8月",
		"title": "受益人次",
		"data": "80.9",
		"unit": "万人次"
	}]);
	data.data_dynamic.push([{
		"date": "本年度",
		"title": "互助金支出",
		"data": "12.19",
		"unit": "亿元"
	}, {
		"date": "本年度",
		"title": "保障人次",
		"data": "2413",
		"unit": "万人次"
	}, {
		"date": "本年度",
		"title": "受益人次",
		"data": "102.8",
		"unit": "万人次"
	}]);
	data.data_dynamic.push([{
		"date": "本年度",
		"title": "互助金支出",
		"data": "9.95",
		"unit": "亿元"
	}, {
		"date": "本年度",
		"title": "保障人次",
		"data": "2179",
		"unit": "万人次"
	}, {
		"date": "本年度",
		"title": "受益人次",
		"data": "74.4",
		"unit": "万人次"
	}]);
	data.data_dynamic.push([{
		"date": "本年度",
		"title": "互助金支出",
		"data": "9.62",
		"unit": "亿元"
	}, {
		"date": "本年度",
		"title": "保障人次",
		"data": "1958",
		"unit": "万人次"
	}, {
		"date": "本年度",
		"title": "受益人次",
		"data": "68.2",
		"unit": "万人次"
	}]);
	data.data_dynamic.push([{
		"date": "本年度",
		"title": "互助金支出",
		"data": "7.02",
		"unit": "亿元"
	}, {
		"date": "本年度",
		"title": "保障人次",
		"data": "1712",
		"unit": "万人次"
	}, {
		"date": "本年度",
		"title": "受益人次",
		"data": "52.5",
		"unit": "万人次"
	}]);
	//默认第一个
	data.cur_data_dynamic = data.data_dynamic[0];
	console.log(JSON.stringify(data.data_dynamic))
}

function changeDataDynamic(index) {
	data.cur_data_dynamic = data.data_dynamic[index];
	data.cur_index = index;
}

function openAppFunction(type, jsonData) {
	const useTimeOut = setTimeout(() => {
		jsToCallApp({
			type: type,
			...jsonData,
		},
			(data) => {
				console.log(type, data)
			},)
		clearTimeout(useTimeOut);
	}, 300);
}
// 获取轮播banner 职工互助保障专区广告位
const getBanner = () => {
	Api.getBanner({
		name: 'Indexad'
	}).then(res => {
		if (res.status == 200) {
			data.swiper = res.data.banner
		}
	})
}
const getMsg = () => {
	Api.newsDetails(19).then(res => {
		if (res.status == 200) {
			console.log(res)
			data.details = res.data
			const jsonString = {
				shareUrl: 'https://quanzong.cwmia.com/#/', //分享链接
				shareCorver: 'https://quanzong.cwmia.com/logo.png',
				shareTitle: '中国职工保障互助会',
				shareDesc: data.details.zhaiyao, //分享描述
			}
			console.log(jsonString)
			openAppFunction('setupShareData', {
				shareData: JSON.stringify(jsonString)
			})
		}
	})
}

// 、、跳转保障业务服务
const toGuarantee = () => {
	router.push({
		path: '/guarantee'
	})
};
// 、、跳转会员服务项目
const toMember = () => {
	router.push({
		path: '/member'
	})
};
const toBannerDetails = (url) => {

	if (url) {
		window.location.href = url
	}
};
// 、、跳转新闻详情
const toNewDetails = (id) => {
	router.push({ path: '/news/details', query: { id, } })
};
</script>
<style lang='less' scoped>
/deep/ .van-tabs__wrap {
	border-radius: 10px;
}

.home {
	display: flex;
	flex-flow: column;
	height: 100%;
	font-size: 12px;

	.details_text {
		text-align: justify;
		margin-top: -0.2rem;
		display: inline-block;
		margin-bottom: -0.4rem;
	}

	.content {
		flex: 1;
		overflow-y: auto;
		background-color: #f5f5f5;
		padding-bottom: .4rem;

		.van-swipe {
			height: 5.3333rem;
			position: relative;

			.van-swipe-item {
				display: flex;
				justify-content: center;
				align-items: center;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			.empty_img {
				width: 1.6rem;
				height: 1.6rem;
			}
		}

		.custom-indicator {

			position: absolute;
			bottom: .1333rem;
			left: 0;
			width: 100%;
			display: flex;
			justify-content: center;

			.box {
				width: .1067rem;
				height: .1067rem;
				border-radius: .1067rem;
				background-color: #fff;
				margin-right: 3px;
			}

			.active {
				width: .2533rem;
			}
		}

		.right_img {
			width: .16rem;
		}

		.home_header {
			width: 9.1733rem;
			height: 1.1333rem;
			background-color: #fff;
			border-radius: .1333rem;
			padding: .1733rem .2rem;

			img {
				width: 3.1333rem;
			}
		}

		.cred {
			color: #D80010;
			font-weight: bold;
		}

		.btn_view {
			width: 9.1733rem;
			height: 4.4933rem;
			background-color: #fff;
			border-radius: .1333rem;
			margin: 0 auto;

			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			padding: .2667rem;

			>div {
				width: 4.1733rem;
				height: 1.8rem;
				margin-bottom: .2667rem;

				img {
					width: 100%;
				}
			}
		}

		.banner {
			width: 100%;
			height: 5.3867rem;
			text-align: center;
			background-color: rgba(240, 240, 240);
			overflow: hidden;

			.banner_img {
				width: 100%;
				height: 100%;
				object-fit: cover;

			}

			.empty_img {
				width: 1.6rem;
				height: 1.6rem;
				margin-top: 2rem;
			}
		}

		.list_view {
			width: 9.1733rem;
			background: #FFFFFF;
			border-radius: .1333rem;
			padding: 0 .4rem 0rem;
			margin: .3333rem auto;
			margin-bottom: .2667rem;

			.list_view_title {
				width: 7.1467rem;
				height: 1.2rem;
				position: relative;
				margin: 0 auto;
				font-size: .5067rem;
				font-weight: 600;
				margin-bottom: .48rem;

				.btn_title_bg {
					width: 100%;
					position: absolute;
					left: 0;
					top: -0.0667rem;
				}

				.text {
					width: 100%;
					height: 100%;
					text-align: center;
					position: relative;
					z-index: 2;

					.logo_img {
						width: 70%;
					}
				}

			}

			.list_nav {
				width: 100%;
				margin-bottom: 0.2rem;
			}

			.list_nav ul {
				list-style: none;
				display: flex;
			}

			.list_nav li {
				flex: 5;
				/* 平分容器宽度 */
				padding: 5px;
				text-align: center;
				background: #F5F5F5;
				font-weight: bold;
				font-size: 0.4rem;
				color: #333333;
				margin-right: 2px;
			}

			.list_nav li:first-child {
				border-radius: 5px 0px 0px 5px;
			}

			.list_nav li:last-child {
				border-radius: 0px 5px 5px 0px;
			}

			.list_nav .li_select {
				background: #D40010;
				color: #fff;
			}

			.data_lists {
				border-bottom: 1px dashed #ccc;
				width: 100%;
				display: inline-block;
				line-height: 1rem;

				&:last-child {
					border: none;
				}
			}

			.data_title {
				float: left;
				font-weight: 400;
				font-size: 0.4rem;
				color: #333333;
				text-align: left;
				text-transform: none;
				margin-left: 0.2rem;
			}

			.data_data {
				float: right;
			}

			.data_data span {
				font-weight: bold;
				font-size: 23px;
				color: #D80010;
				text-align: left;
			}

			.data_data em {
				text-align: left;
				font-style: normal;
				display: inline-block;
				width: 1rem;
				margin-left: 0.1rem;
			}
		}
	}
}
</style>
